import { Application } from "@hotwired/stimulus";
import AutoSubmit from "@stimulus-components/auto-submit";
import Dropdown from "@stimulus-components/dropdown";
import { registerControllers } from "stimulus-vite-helpers";

declare global {
  interface Window {
    Stimulus: Application;
  }
}

const application = Application.start();
window.Stimulus = application;

const controllers = import.meta.glob("./**/*_controller.ts", { eager: true });
registerControllers(application, controllers);

application.register("dropdown", Dropdown);
application.register("auto-submit", AutoSubmit);
