import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

const keycodes = { space: 32, enter: 13 };

export default class extends Controller {
  visitDataUrl(event) {
    if (
      event.type == "click" ||
      event.keyCode == keycodes.space ||
      event.keyCode == keycodes.enter
    ) {
      if (event.target.href) {
        return;
      }

      var dataUrl = event.target.closest("tr").dataset.url;
      var selection = window.getSelection().toString();
      if (selection.length === 0 && dataUrl) {
        Turbo.visit(dataUrl);
      }
    }
  }
}
